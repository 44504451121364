<template>
  <div class="wrapper">
    <div>
      <button
        class="back btn btn-sm btn-outline-secondary shadow-none"
        @click="back"
      >
        <i class="bi bi-arrow-left"></i> назад
      </button>
      <button
        v-if="
          USER_INFO.status === 'admin' &&
            orderInfo &&
            orderInfo.percent &&
            orderInfo.date_confirm &&
            !orderInfo.date_order_finished
        "
        class="back btn btn-sm btn-outline-primary shadow-none"
        @click="orderFinishedPercent"
      >
        1,5% отгружен
      </button>
    </div>
    <div class="table-wrapper" v-if="order && order.length">
      <table class="table table-sm">
        <tr v-if="USER_INFO.status === 'admin'">
          <td colspan="6">
            <a
              :href="
                `https://sc-kalibr.ru/api/repairs/repairs_orders/zap_in_excel.php?id=1&order_zap_in_excel=${orderInfo.id}`
              "
              target="blank"
              >экспорт в excel</a
            >
          </td>
        </tr>
        <tr>
          <td :colspan="USER_INFO.status === 'admin' ? 6 : 5">
            <FileForOrder @update="get_data" :orderInfo="orderInfo" />
          </td>
        </tr>
        <tr>
          <th></th>
          <th>наименование</th>
          <th>шт</th>
          <th>код</th>
          <th>артикул</th>
          <th v-if="USER_INFO.status === 'admin'">остаток</th>
          <th v-if="USER_INFO.status === 'admin'"></th>
        </tr>
        <tr
          v-for="(item, idx) in order"
          :key="idx"
          :class="item.aaa && 'text-warning'"
        >
          <td>{{ idx + 1 }}</td>
          <td>
            {{ item.name }}
            <div v-if="item.nomer_nakl" class="nakl">
              {{ item.nomer_nakl }} от {{ item.data_nakl }}
            </div>
          </td>
          <td class="text-center">
            <div>{{ item.pcs }}</div>
            <EditPcsZapNotForRepairs
              :pcs="item.pcs"
              :cod="item.cod"
              :id="item.id"
              v-if="USER_INFO.status === 'admin'"
              @update="get_data"
              url="order_zap/edit_pcs_zap.php"
            />
          </td>
          <td>{{ item.cod }}</td>
          <td>{{ item.art }}</td>
          <td v-if="USER_INFO.status === 'admin'">{{ item.ostatok }}</td>
          <td v-if="USER_INFO.status === 'admin' && !item.nomer_nakl">
            <i class="bi bi-x" @click="delZap(item.id, item.name)"></i>
          </td>
        </tr>
        <tr v-if="USER_INFO.status === 'admin' && !orderInfo.order_finished">
          <td colspan="7">
            <div v-if="USER_INFO.status === 'admin' && count_new_orders">
              <div
                v-for="(item, idx) in count_new_orders"
                :key="idx + 'add'"
                class="mb-2"
              >
                <button
                  class="btn btn-sm btn-outline-primary shadow-none"
                  @click="add_zap_in_other_order(item.id)"
                >
                  добавить запчасти из заказа
                  {{ `${item.id} от ${item.date_send}` }}
                </button>
              </div>
            </div>
            <button
              class="btn btn-sm btn-outline-success shadow-none"
              @click="visible"
            >
              добавить запчасть
            </button>
          </td>
        </tr>
      </table>

      <div class="comment" v-if="orderInfo && orderInfo.comment">
        <div class="comment-title">комментарий</div>
        <div>{{ orderInfo.comment }}</div>
      </div>
    </div>
    <div class="col-md-6" v-if="visibleAddZap">
      <SelectZapForRepair
        :oldzapvisible="false"
        :newZapTitle="``"
        :cenaVisible="true"
        :visible="true"
        :getParams="
          USER_INFO.status === 'admin'
            ? { onlyInStock: false }
            : { onlyInStock: true }
        "
        :title="'поиск запчастей по артикулу, наименованию или коду'"
      />
      <div>
        <button
          class="btn btn-sm btn-outline-primary shadow-none"
          v-if="ZAP.length"
          @click="addZap"
        >
          сохранить
        </button>
        <button
          class="btn btn-sm btn-outline-danger shadow-none"
          v-if="ZAP.length"
          @click="cancelAddZap"
        >
          отмена
        </button>
      </div>
    </div>
    <div class="col-md-6 mt-4" v-if="order && order.length">
      <div>
        <CdekInfo
          :order_info="orderInfo"
          @update="update"
          type="zap_order"
          width="12"
        />
      </div>
      <Chat
        urlSendMessage="repairs/chat/chat_of_zap_order.php"
        urlDeleteMessage="repairs/chat/del_zap_order_message.php"
        chatId="chat_of_zap_order"
        title="переписка по заказу"
      />
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapGetters } from "vuex";
import SelectZapForRepair from "@/components/SelectZapForRepair.vue";
import FileForOrder from "./FileForOrder.vue";
import EditPcsZapNotForRepairs from "@/views/repairs/repairsOrders/EditPcsZapNotForRepairs.vue";
import Chat from "@/views/repairs/chat/Chat.vue";
import CdekInfo from "@/views/repairs/repairsOrders/CdekInfo.vue";
export default {
  name: "OneOrderZap",
  components: {
    SelectZapForRepair,
    FileForOrder,
    EditPcsZapNotForRepairs,
    Chat,
    CdekInfo
  },
  computed: {
    ...mapGetters(["USER_INFO", "ZAP"])
  },
  data: () => ({
    orderInfo: null,
    order: null,
    visibleAddZap: false,
    count_new_orders: null
  }),
  mounted() {
    this.get_data();
  },
  methods: {
    update() {
      this.get_data();
    },
    get_data() {
      HTTPGET({
        url: "/order_zap/get_one_order.php",
        params: {
          id: this.$route.params.id
        }
      }).then(response => {
        this.orderInfo = response.data.order_info;
        this.order = response.data.order;
        this.count_new_orders = response.data.new_orders;
        this.order.map(item => {
          let cod = item.cod;
          let count = 0;
          this.order.map(item2 => {
            if (cod == item2.cod) {
              count++;
              if (count > 1) {
                item.aaa = true;
              }
            }
          });
        });
      });
    },
    back() {
      if (this.GET_PATH_FROM === "/login") {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    delZap(id, name) {
      if (confirm(`удалить ${name}`)) {
        HTTPGET({
          url: "/order_zap/del_zap_from_order.php",
          params: {
            id,
            order_id: this.$route.params.id
          }
        }).then(() => this.get_data());
      }
    },
    addZap() {
      HTTPGET({
        url: "/order_zap/add_zap_in_order.php",
        params: {
          zap: this.ZAP,
          order_id: this.$route.params.id
        }
      })
        .then(() => this.get_data())
        .then(() => (this.visibleAddZap = false));
    },
    visible() {
      this.visibleAddZap = !this.visibleAddZap;
    },
    cancelAddZap() {
      this.visibleAddZap = false;
    },
    orderFinishedPercent() {
      if (confirm("заказ отгружен")) {
        HTTPGET({
          url: "/order_zap/order_finish_percent.php",
          params: {
            order_id: this.$route.params.id
          }
        }).then(() => this.get_data());
      }
    },
    add_zap_in_other_order(id) {
      if (confirm(`добавить запчасти из заказа ${id}`)) {
        HTTPGET({
          url: "/order_zap/consolidation_orders.php",
          params: {
            order_id: this.$route.params.id,
            order_id_add: id
          }
        }).then(() => this.get_data());
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 10px;
}
.table-wrapper {
  overflow: auto;
  margin-top: 10px;
}
table,
.comment {
  font-size: 0.8em;
}
td {
  vertical-align: middle;
}
.comment-title {
  font-weight: bold;
}
.bi-x {
  color: #ff0000;
  font-size: 1.2em;
  cursor: pointer;
}
button {
  margin-right: 10px;
}
.nakl {
  font-size: 0.7em;
  color: #007bff;
}
</style>
